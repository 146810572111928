import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
//
import { addStoryItem, addSubcollectionItem } from '../services/addServices';
import { USER_STATUS } from '../constants/keywords';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../constants/collections';
import { FIREBASE_API } from '../config';
import { getStoryItem } from '../services/getServices';
import { FIREBASE_OPERATORS } from '../constants/operators';
import { getSubcollectionDocument } from '../services/checkDocExists';
import { formateDDMMYYYY } from '../utils/newDateToDDMMYYYY';

// ----------------------------------------------------------------------

// const ADMIN_EMAILS = ['admin@gmail.com'];
const ADMIN_EMAILS = ['sj@diginfoexpert.com'];

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const STORAGE = getStorage(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isFirstTime: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, isFirstTime } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      isFirstTime,
    };
  }
  if (action.type === 'PROFILE_UPDATE') {
    const { isFirstTime } = action.payload;

    return {
      ...state,
      isFirstTime,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState(null);
  // const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const sub = onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        const userRef = doc(DB, FIREBASE_COLLECTIONS.users, user.uid);
        const docSnap = await getDoc(userRef);
        if (user) {
          if (docSnap.exists()) {
            setProfile(docSnap.data());

            // if (user.email !== 'admin@gmail.com') {
              if (user.email !== 'sj@diginfoexpert.com') {
              const documentData = await getSubcollectionDocument(
                FIREBASE_COLLECTIONS.users,
                user?.uid,
                FIREBASE_SUB_COLLECTIONS.dailyActivity,
                formateDDMMYYYY(new Date())
              );

              if (Object.keys(documentData).length === 0) {
                await addSubcollectionItem(
                  FIREBASE_COLLECTIONS.users,
                  user?.uid,
                  FIREBASE_SUB_COLLECTIONS.dailyActivity,
                  {
                    loginTime: new Date(),
                    breaks: [],
                    logoutTime: '',
                    totalHours: '',
                  },
                  formateDDMMYYYY(new Date())
                );
              }
            }
          } else {
            setProfile(null);
          }
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user, isFirstTime: !docSnap.exists() },
          });
          console.log('!docSnap.exists() :>> ', !docSnap.exists());
        } else {
          setProfile(null);
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: false, user: null },
        });
      }
    });
    return sub;
  }, []);

  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  const logout = () => signOut(AUTH);

  const register = ({ email, password, firstName, lastName, ...rest }) =>
    new Promise((resolve) => {
      createUserWithEmailAndPassword(AUTH, email, password)
        .then(async (res) => {
          const response = await addStoryItem(
            FIREBASE_COLLECTIONS.users,
            {
              uid: res.user?.uid,
              email,
              displayName: `${firstName} ${lastName}`,
              createdAt: new Date(),
              createdBy: res.user?.uid,
              ...rest,
            },
            res.user?.uid
          );
          logout();
          resolve(response);
        })
        .catch(() => resolve(''));
    });

  const updateProfile = async (userId) => {
    try {
      const profiles = await getStoryItem(FIREBASE_COLLECTIONS.users, [
        { property: 'uid', operator: FIREBASE_OPERATORS.EQUAL_TO, value: userId },
      ]);

      setProfile(profiles[0]);

      const documentData = await getSubcollectionDocument(
        FIREBASE_COLLECTIONS.users,
        userId,
        FIREBASE_SUB_COLLECTIONS.dailyActivity,
        formateDDMMYYYY(new Date())
      );

      if (Object.keys(documentData).length === 0) {
        await addSubcollectionItem(
          FIREBASE_COLLECTIONS.users,
          userId,
          FIREBASE_SUB_COLLECTIONS.dailyActivity,
          {
            loginTime: new Date(),
            breaks: [],
            logoutTime: '',
            totalHours: '',
          },
          formateDDMMYYYY(new Date())
        );
      }

      dispatch({
        type: 'PROFILE_UPDATE',
        payload: { isFirstTime: false },
      });
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : profile?.designation,
          phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          ...profile,
        },
        login,
        register,
        logout,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
