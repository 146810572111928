import { collection, doc, setDoc } from 'firebase/firestore';
import { AUTH, DB } from '../contexts/FirebaseContext';

// for adding data in collection

export const addStoryItem = (collectionName, data, defaultId = '') =>
  new Promise((resolve) => {
    const newStoryItemRef = defaultId
      ? doc(collection(DB, collectionName), defaultId)
      : doc(collection(DB, collectionName));
    setDoc(newStoryItemRef, {
      ...data,
      id: newStoryItemRef?.id,
      createdAt: new Date(),
      createdBy: AUTH?.currentUser?.uid,
    })
      .then(() => resolve(newStoryItemRef?.id))
      .catch(() => resolve(false));
  });

// for adding data in subcollection

export const addSubcollectionItem = (mainCollectionName, docId, subcollectionName, data, date = '') =>
  new Promise((resolve) => {
    const mainDocRef = doc(DB, mainCollectionName, docId);
    const subcollectionRef = collection(mainDocRef, subcollectionName);

    let newSubcollectionItemRef;
    if (date) {
      // Use provided date as document ID
      newSubcollectionItemRef = doc(subcollectionRef, date);
    } else {
      // Firebase generates an ID
      newSubcollectionItemRef = doc(subcollectionRef);
    }

    setDoc(newSubcollectionItemRef, {
      ...data,
      id: newSubcollectionItemRef.id,
      createdAt: new Date(),
      createdBy: AUTH.currentUser.uid,
    })
      .then(() => resolve(newSubcollectionItemRef.id))
      .catch(() => resolve(false));
  });
