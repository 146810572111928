export const bloodGroup = [
  {
    label: 'A+',
    value: 'a+',
  },
  {
    label: 'A-',
    value: 'a-',
  },
  {
    label: 'B+',
    value: 'b+',
  },
  {
    label: 'B-',
    value: 'b-',
  },
  {
    label: 'AB+',
    value: 'ab+',
  },
  {
    label: 'AB-',
    value: 'ab-',
  },
  {
    label: 'O+',
    value: 'o+',
  },
  {
    label: 'O-',
    value: 'o-',
  },
];
