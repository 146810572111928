import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import { getSubcollectionItems } from '../../../../services/getServices';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../../../../constants/collections';
import { FIREBASE_OPERATORS } from '../../../../constants/operators';
import SpinnerLoader from '../../../../components/SpinnerLoader';

const ReportSection = ({ user }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedReport, setSelectedReport] = useState('');
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModelOpen, setIsModelOpen] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      const queryList = [
        {
          property: 'createdAt',
          operator: FIREBASE_OPERATORS.GREATER_THAN_EQUAL_TO,
          value: startOfDay,
        },
        {
          property: 'createdAt',
          operator: FIREBASE_OPERATORS.LESS_THAN_EQUAL_TO,
          value: endOfDay,
        },
      ];

      const res = await getSubcollectionItems(
        FIREBASE_COLLECTIONS.users,
        user?.id,
        FIREBASE_SUB_COLLECTIONS.report,
        queryList
      );
      setReports(res);
      setLoading(false);
    };

    fetchReports();
  }, [selectedDate, user?.id]);

  // handle view report

  const handleViewReport = (item) => {
    const { about, doc } = item;
    setSelectedReport(doc);
    // setIsModelOpen(true);
    // window.open(doc, '_blank');
    downloadFile(doc);
  };

  const downloadFile = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileUrl.split('/').pop();
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      enqueueSnackbar('Downloaded successfully..!');
    } catch (error) {
      console.error('Error downloading file:', error);
      enqueueSnackbar('There is some issues!', { variant: 'error' });
    }
  };
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: '2rem' }}>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      {loading ? (
        <SpinnerLoader message={'Fetching Reports'} />
      ) : (
        <>
          <Grid container spacing={3}>
            {reports?.length > 0 ? (
              reports.map((report, reportIndex) =>
                report?.reports?.map((item, itemIndex) => (
                  <Grid item xs={12} sm={12} md={3}>
                    <Card elevation={5} key={`${reportIndex}-${itemIndex}`} sx={{ mb: 2, p: 2 }}>
                      <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="subtitle2">{item.about}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button onClick={() => handleViewReport(item)} variant="contained">
                          View
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))
              )
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '30vh',
                  }}
                >
                  <h1>No reports for the selected date.</h1>
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default ReportSection;
