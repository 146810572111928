import { paramCase } from 'change-case';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Grid,
} from '@mui/material';
// routes
import { useSnackbar } from 'notistack';
import UserTableToolbar from '../../../sections/@dashboard/master/list/UserTableToolbar';
import UserTableRow from '../../../sections/@dashboard/master/list/UserTableRow';
import { updateStoryItem } from '../../../services/updateServices';
import useAuth from '../../../hooks/useAuth';
import { FIREBASE_OPERATORS } from '../../../constants/operators';
import { FIREBASE_COLLECTIONS } from '../../../constants/collections';
import useFirebaseData from '../../../hooks/useFirebaseData';
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../../hooks/useTable';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import Loader from '../../../components/Loader';
import { downloadJSONtoExcel } from '../../../utils/jsonToExcel';
import { getStoryItem } from '../../../services/getServices';
import { USER_ROLES_MAPPER } from '../../../constants/keywords';
import UserItem from '../../../sections/@dashboard/users/Rating/UsertItem';
import UserRatingToolbar from '../../../sections/@dashboard/users/Rating/UserRatingToolbar';
import SpinnerLoader from '../../../components/SpinnerLoader';

// ----------------------------------------------------------------------

export default function RatingPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const { user } = useAuth();

  const { data: tableData, isFetching } = useFirebaseData(FIREBASE_COLLECTIONS.users, [
    {
      property: 'designation',
      operator: FIREBASE_OPERATORS.EQUAL_TO,
      value: 'user',
    },
    {
      property: 'uid',
      operator: FIREBASE_OPERATORS.NOT_EQUAL_TO,
      value: user?.uid,
    },
  ]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [filterName, setFilterName] = useState('');

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const dataFiltered = applySortFilter({
    tableData: tableData && tableData.length > 0 ? tableData : [],
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = dense ? 52 : 72;

  return (
    <Page title="Ratings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Users Rating" links={[{ name: 'Rating', href: PATH_DASHBOARD.master.rating }]} />
        {isFetching ? (
          <SpinnerLoader />
        ) : (
          <Box>
            <UserRatingToolbar filterName={filterName} onFilterName={handleFilterName} />
            <Grid container spacing={3} mt={5}>
              {dataFiltered.map((row, index) => (
                <Grid item xs={12} sm={12} md={4}>
                  <UserItem key={index} user={row} mainUser={user?.id} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.displayName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  return tableData;
}
