import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import React from 'react';

const SpinnerLoader = ({ message }) => {
  const theme = useTheme();
  const mainLoaderStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  };

  const loaderStyle = {
    width: '100px',
    aspectRatio: '1',
    display: 'grid',
    border: '5px solid #0000',
    borderRadius: '50%',
    borderRightColor: theme.palette.primary.main,
    animation: 'l15 1s infinite linear',
    position: 'relative',
  };

  const beforeAfterStyle = {
    content: '""',
    gridArea: '1/1',
    margin: '2px',
    border: 'inherit',
    borderRadius: '50%',
    animation: 'l15 2s infinite',
  };

  const afterStyle = {
    ...beforeAfterStyle,
    margin: '8px',
    animationDuration: '3s',
  };

  return (
    <div style={mainLoaderStyle}>
      <div style={loaderStyle}>
        <div style={beforeAfterStyle}>{''}</div>
        <div style={afterStyle}>{''}</div>
      </div>
      <Typography sx={{ fontWeight: '750', marginLeft: '1.2rem', marginTop: '8px' }}>{message}</Typography>
    </div>
  );
};

export default SpinnerLoader;
