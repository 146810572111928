import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Rating } from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Iconify from '../../../../components/Iconify';
import { getSubcollectionDocument } from '../../../../services/checkDocExists';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../../../../constants/collections';
import useFirebaseData from '../../../../hooks/useFirebaseData';
import { FIREBASE_OPERATORS } from '../../../../constants/operators';
import { getStoryItem } from '../../../../services/getServices';
import SpinnerLoader from '../../../../components/SpinnerLoader';

const UserRated = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data, isFetching } = useFirebaseData(FIREBASE_COLLECTIONS.ratings, [
    {
      property: 'raterId',
      operator: FIREBASE_OPERATORS.EQUAL_TO,
      value: user?.id,
    },
  ]);

  const [ratedUsers, setRatedUsers] = useState([]);

  useEffect(() => {
    const fetchRatedUsers = async () => {
      setIsLoading(true);
      if (data && data.length > 0) {
        const userPromises = data.map(async (item) => {
          const userDoc = await getStoryItem(FIREBASE_COLLECTIONS.users, [
            {
              property: 'id',
              operator: FIREBASE_OPERATORS.EQUAL_TO,
              value: item.ratedUserId,
            },
          ]);

          // Assuming getStoryItem returns an array of matching documents
          const user = userDoc.length > 0 ? userDoc[0] : null;

          return { ...user, rating: item.rating };
        });

        const users = await Promise.all(userPromises);
        setRatedUsers(users);
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    fetchRatedUsers();
  }, [data]);

  return (
    <>
      {/* <Typography variant="h4" sx={{ my: 5 }}>
        User Rated.
      </Typography> */}

      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Box
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
        >
          {ratedUsers.length === 0 ? (
            <h1>not given rating to anyone</h1>
          ) : (
            ratedUsers?.map((follower, index) => <FollowerItem key={index} follower={follower} />)
          )}
        </Box>
      )}
    </>
  );
};

export default UserRated;

function FollowerItem({ follower }) {
  console.log('follower :>> ', follower);
  //   const { name, country, avatarUrl } = follower;

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: (theme) => theme.spacing(3, 2, 3, 3),
      }}
    >
      <Avatar alt={follower?.displayName} src={follower?.photoURL} sx={{ width: 48, height: 48, mr: 2 }} />

      <ListItemText
        primary={follower?.displayName}
        secondary={
          <>
            <Iconify icon="mingcute:location-fill" width={16} sx={{ flexShrink: 0, mr: 0.5 }} />
            {/* {country} */}
            {/* {follower?.rating} */}
            {/* <Rating name="disabled" defaultValue={follower?.rating} disabled max={10} /> */}
            <Rating name="disabled" defaultValue={follower?.rating} disabled max={5} />
          </>
        }
        primaryTypographyProps={{
          noWrap: true,
          typography: 'subtitle2',
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          noWrap: true,
          display: 'flex',
          component: 'span',
          alignItems: 'center',
          typography: 'caption',
          color: 'text.disabled',
        }}
      />
    </Card>
  );
}
