import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { Rating } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import Iconify from '../../../../components/Iconify';
import { FIREBASE_COLLECTIONS } from '../../../../constants/collections';
import { addStoryItem } from '../../../../services/addServices';
import useFirebaseData from '../../../../hooks/useFirebaseData';
import { FIREBASE_OPERATORS } from '../../../../constants/operators';
import { getSubcollectionDocument } from '../../../../services/checkDocExists';
import { getStoryItem } from '../../../../services/getServices';
import { updateStoryItem } from '../../../../services/updateServices';

// ----------------------------------------------------------------------

export default function UserItem({ user, mainUser }) {
  const { enqueueSnackbar } = useSnackbar();
  const [rate, setRate] = useState(0);

  const { data, isFetching } = useFirebaseData(FIREBASE_COLLECTIONS.ratings, [
    {
      property: 'raterId',
      operator: FIREBASE_OPERATORS.EQUAL_TO,
      value: mainUser,
    },
    {
      property: 'ratedUserId',
      operator: FIREBASE_OPERATORS.EQUAL_TO,
      value: user?.id,
    },
  ]);

  useEffect(() => {
    if (data && data.length > 0 && data[0]?.rating !== undefined) {
      setRate(data[0].rating);
    }
  }, [data]);

  const handleGiveRating = async (e, newRating) => {
    console.log('helelo :>> ');
    const obj = {
      ratedUserId: user?.id,
      raterId: mainUser,
      rating: newRating,
      timeStamp: new Date(),
    };
    try {
      const res = await getStoryItem(FIREBASE_COLLECTIONS.ratings, [
        {
          property: 'raterId',
          operator: FIREBASE_OPERATORS.EQUAL_TO,
          value: mainUser,
        },
        {
          property: 'ratedUserId',
          operator: FIREBASE_OPERATORS.EQUAL_TO,
          value: user?.id,
        },
      ]);

      console.log('res :>> ', res);

      if (res.length === 0) {
        await addStoryItem(FIREBASE_COLLECTIONS.ratings, obj);
      } else {
        const userRating = res[0];
        await updateStoryItem(FIREBASE_COLLECTIONS.ratings, {
          ...obj,
          id: userRating?.id,
        });
      }

      setRate(newRating);
      enqueueSnackbar('Rated successfully..!');
    } catch (error) {
      console.log('error :>> ', error);
      enqueueSnackbar('There is some issues!', { variant: 'error' });
    }
  };

  return (
    <>
      {isFetching ? (
        // <h1>Loading...</h1>
        <Skeleton variant="rectangular" width={210} height={118} />
      ) : (
        <Card>
          <Stack sx={{ p: 3, pb: 2 }}>
            <Avatar alt={'Avatar'} src={user?.photoURL} variant="rounded" sx={{ width: 48, height: 48, mb: 2 }} />

            <ListItemText
              sx={{ mb: 1 }}
              primary={
                <Link component={Link} href={''} color="inherit">
                  {user?.displayName}
                </Link>
              }
              // secondary={`Posted date: ${fDate(createdAt)}`}
              primaryTypographyProps={{
                typography: 'subtitle1',
              }}
              secondaryTypographyProps={{
                mt: 1,
                component: 'span',
                typography: 'caption',
                color: 'text.disabled',
              }}
            />

            <Stack
              spacing={0.5}
              direction="row"
              alignItems="center"
              sx={{ color: 'primary.main', typography: 'caption' }}
            >
              <Iconify width={16} icon="solar:users-group-rounded-bold" />
              {user?.email}
            </Stack>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Box rowGap={1.5} display="grid" gridTemplateColumns="repeat(2, 1fr)" sx={{ p: 3 }}>
            {/* <Rating name="customized-10" defaultValue={rate} max={10} onChange={handleGiveRating} /> */}
            <Rating name="customized-10" defaultValue={rate} max={5} onChange={handleGiveRating} />
            <Typography>{rate}/5</Typography>
          </Box>
        </Card>
      )}
    </>
  );
}
