import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import useTabs from '../../../hooks/useTabs';
import { AccountGeneral } from '../../../sections/@dashboard/master/account';
import AccountProfile from '../../../sections/@dashboard/users/AccountProfile';
import ReportForm from '../../../sections/@dashboard/users/ReportForm';

// ----------------------------------------------------------------------

export default function ReportPage() {
  const { themeStretch } = useSettings();

  const { currentTab, onChangeTab } = useTabs('general');

  const ACCOUNT_TABS = [
    {
      value: 'general',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <AccountGeneral />,
    },
  ];

  return (
    <Page title="Report">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Report"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.app },
            { name: 'Report', href: PATH_DASHBOARD.master.report },
          ]}
        />

        <Box sx={{ mb: 5 }} />

        <ReportForm />
      </Container>
    </Page>
  );
}
