import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import FormControlLabel from '@mui/material/FormControlLabel';

import Iconify from '../../../../components/Iconify';
import CustomPopover from '../../../../components/custom-popover/customPopover';
import usePopover from '../../../../components/custom-popover/usePopover';
import { USER_ROLES } from '../../../../constants/keywords';
import useAuth from '../../../../hooks/useAuth';
import { updateSubcollectionItem } from '../../../../services/updateServices';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../../../../constants/collections';
import { deleteSubcollectionItem } from '../../../../services/deleteServices';

// ----------------------------------------------------------------------

export default function Tasks({ title, userId, subheader, list, ...other }) {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    setTasks(list);
  }, [list]);

  const removeTask = (taskId) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      {tasks.map((task) => (
        <TaskItem key={task?.id} task={task} checked={task?.isCompleted} userId={userId} removeTask={removeTask} />
      ))}
    </Card>
  );
}

Tasks.propTypes = {
  list: PropTypes.array,
  subheader: PropTypes.string,
  title: PropTypes.string,
  userId: PropTypes.string,
};

// ----------------------------------------------------------------------

function TaskItem({ task, checked, userId, removeTask }) {
  const [isChecked, setIsChecked] = useState(checked);
  const popover = usePopover();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const handleMarkComplete = async () => {
    try {
      await updateSubcollectionItem(FIREBASE_COLLECTIONS.users, userId, FIREBASE_SUB_COLLECTIONS.pendingTask, {
        id: String(task?.id),
        isCompleted: !isChecked,
      });
      setIsChecked(!isChecked);
      if (isChecked) {
        enqueueSnackbar('Task UnCompleted...!');
      } else {
        enqueueSnackbar('Task Completed...!');
      }
    } catch (error) {
      console.log('error :>> ', error);
      enqueueSnackbar('There is some issues!', { variant: 'error' });
    }
    popover.onClose();
  };
  const handleDelete = async () => {
    try {
      await deleteSubcollectionItem(FIREBASE_COLLECTIONS.users, userId, FIREBASE_SUB_COLLECTIONS.pendingTask, task?.id);
      removeTask(task.id);
      enqueueSnackbar('Task Deleted...!');
    } catch (error) {
      console.log('error :>> ', error);
      enqueueSnackbar('There is some issue!', { variant: 'error' });
    }
    popover.onClose();
  };

  const handleCheckTask = async () => {
    try {
      await updateSubcollectionItem(FIREBASE_COLLECTIONS.users, userId, FIREBASE_SUB_COLLECTIONS.pendingTask, {
        id: String(task?.id),
        isCompleted: !isChecked,
      });
      setIsChecked(!isChecked);
      if (isChecked) {
        enqueueSnackbar('Task UnCompleted...!');
      } else {
        enqueueSnackbar('Task Completed...!');
      }
    } catch (error) {
      console.log('error :>> ', error);
      enqueueSnackbar('There is some issues!', { variant: 'error' });
    }
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          pl: 2,
          pr: 1,
          py: 1,
          '&:not(:last-of-type)': {
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          },
          ...(isChecked && {
            color: 'text.disabled',
            textDecoration: 'line-through',
          }),
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleCheckTask} />}
          label={task?.task}
          sx={{ flexGrow: 1, m: 0 }}
        />

        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top">
        <MenuItem onClick={handleMarkComplete}>
          <Iconify icon="eva:checkmark-circle-2-fill" />
          {isChecked ? 'Mark UnCompleted' : 'Mark Completed'}
        </MenuItem>

        {[USER_ROLES.ADMIN].includes(user?.role) && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete
            </MenuItem>
          </>
        )}
      </CustomPopover>
    </>
  );
}

TaskItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  task: PropTypes.object,
  userId: PropTypes.string,
};
