import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Container, InputBase, Stack, alpha } from '@mui/material';
import { useSnackbar } from 'notistack';
import { format, isSameDay } from 'date-fns';
import useAuth from '../../../hooks/useAuth';
import FileDataActivity from '../../../components/chart/FileDataActivity';
import Page from '../../../components/Page';
import { getSubcollectionItems } from '../../../services/getServices';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../../../constants/collections';
import Tasks from '../../../sections/@dashboard/users/PendingTask/Tasks';
import useSettings from '../../../hooks/useSettings';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SpinnerLoader from '../../../components/SpinnerLoader';
import { addSubcollectionItem } from '../../../services/addServices';

const PendingTask = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [tasks, setTasks] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [assignedTask, setAssignedTask] = useState();
  const [isAssigning, setIsAssigning] = useState(false);

  useEffect(() => {
    const fetchAllPendingTask = async () => {
      try {
        setIsFetching(true);
        const response = await getSubcollectionItems(
          FIREBASE_COLLECTIONS.users,
          user?.id,
          FIREBASE_SUB_COLLECTIONS.pendingTask
        );
        setTasks(response);
        setIsFetching(false);
        console.log('response :>> ', response);
      } catch (error) {
        console.log('error :>> ', error);
        setIsFetching(false);
      }
      setIsFetching(false);
    };
    fetchAllPendingTask();
  }, [user, isAssigning]);

  const handleAssignTask = async () => {
    try {
      setIsAssigning(true);
      const res = await addSubcollectionItem(
        FIREBASE_COLLECTIONS.users,
        user?.id,
        FIREBASE_SUB_COLLECTIONS.pendingTask,
        {
          task: assignedTask,
          isCompleted: false,
        }
      );
      setIsAssigning(false);
      setAssignedTask('');
      enqueueSnackbar('Pending task submited successfully.');
    } catch (error) {
      console.log('error :>> ', error);
      setIsAssigning(false);
      enqueueSnackbar('There is some issues!', { variant: 'error' });
    }
    setIsAssigning(false);
  };

  return (
    <Page title="Pending Tasks">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Pending Tasks"
          links={[{ name: 'Tasks', href: PATH_DASHBOARD.master.pendingTask }]}
        />
        {isFetching ? <SpinnerLoader /> : <Tasks list={tasks} userId={user?.id} />}

        <Box>
          <Card sx={{ p: 3, mt: 5 }}>
            <InputBase
              multiline
              fullWidth
              rows={4}
              placeholder="Want to add pending task..."
              value={assignedTask}
              onChange={(e) => setAssignedTask(e.target.value)}
              sx={{
                p: 2,
                mb: 3,
                borderRadius: 1,
                border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
              }}
            />

            <Stack direction="row-reverse" alignItems="center" justifyContent="space-between">
              <LoadingButton variant="contained" onClick={handleAssignTask} loading={isAssigning}>
                Add Pending Task
              </LoadingButton>
            </Stack>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default PendingTask;
