import { deleteDoc, doc, collection, getDocs, query, updateDoc, getDoc, deleteField } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';

// Delete Story Item - set deleteDocument flag to true
export const deleteStoryItem = (collectionName, docId) =>
  new Promise((resolve) => {
    deleteDoc(doc(DB, collectionName, docId))
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });

// deleting from subcollection
export const deleteSubcollectionItem = (mainCollectionName, docId, subcollectionName, subDocId) =>
  new Promise((resolve) => {
    const subDocRef = doc(DB, mainCollectionName, docId, subcollectionName, subDocId);

    deleteDoc(subDocRef)
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });

// delete all fields Except id and uid
export const deleteAllExceptIdAndUid = async (collectionName, docId) => {
  try {
    const docRef = doc(DB, collectionName, docId);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      console.error('Document does not exist');
      return false;
    }

    const docData = docSnapshot.data();
    const updateData = Object.keys(docData).reduce((acc, key) => {
      if (key !== 'id' && key !== 'uid') {
        acc[key] = deleteField();
      }
      return acc;
    }, {});

    await updateDoc(docRef, updateData);
    return true;
  } catch (error) {
    console.error('Error deleting fields from document:', error);
    return false;
  }
};
