import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router';
import { FIREBASE_COLLECTIONS } from '../../../constants/collections';
import { FIREBASE_OPERATORS } from '../../../constants/operators';
import useFirebaseData from '../../../hooks/useFirebaseData';
import { firebaseTimeStampToDate } from '../../../utils/firebaseTimeStampToDate';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import ProfileCover from '../../../sections/@dashboard/users/Details/profileCover';
import ProfileHome from '../../../sections/@dashboard/users/Details/ProfileHome';
import UserRated from '../../../sections/@dashboard/users/Details/UserRated';
import ReportSection from '../../../sections/@dashboard/users/Details/ReportSection';
import TaskSection from '../../../sections/@dashboard/users/Details/TaskSection';
import SpinnerLoader from '../../../components/SpinnerLoader';

const TABS = [
  {
    value: 'profile',
    label: 'Profile',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'report',
    label: 'Reports',
    icon: <Iconify icon="solar:heart-bold" width={24} />,
  },
  {
    value: 'rated',
    label: 'Rated',
    icon: <Iconify icon="solar:users-group-rounded-bold" width={24} />,
  },
  {
    value: 'task',
    label: 'Tasks',
    icon: <Iconify icon="solar:gallery-wide-bold" width={24} />,
  },
];

const UserDetails = () => {
  const { id } = useParams();
  const { themeStretch } = useSettings();
  const [currentTab, setCurrentTab] = useState('profile');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const { data: user, isFetching } = useFirebaseData(FIREBASE_COLLECTIONS.users, [
    {
      property: 'id',
      operator: FIREBASE_OPERATORS.EQUAL_TO,
      value: id,
    },
  ]);

  return (
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <HeaderBreadcrumbs
        heading="User Profile"
        links={[
          { name: 'Users', href: PATH_DASHBOARD.users.list },
          { name: `${id}`, href: PATH_DASHBOARD.users.details },
        ]}
      />

      {isFetching ? (
        <SpinnerLoader message="Fetching User Profile..." />
      ) : (
        <>
          <Card
            sx={{
              mb: 3,
              height: 290,
            }}
          >
            <ProfileCover
              name={user[0]?.displayName}
              avatarUrl={user[0]?.photoURL}
              // coverUrl={_userAbout.coverUrl}
              coverUrl={'images/pic.jpg'}
            />
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              sx={{
                width: 1,
                bottom: 0,
                zIndex: 9,
                position: 'absolute',
                bgcolor: 'background.paper',
                [`& .${tabsClasses.flexContainer}`]: {
                  pr: { md: 3 },
                  justifyContent: {
                    sm: 'center',
                    md: 'flex-end',
                  },
                },
              }}
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
              ))}
            </Tabs>
          </Card>

          {currentTab === 'profile' && <ProfileHome info={user[0]} />}
          {currentTab === 'rated' && <UserRated user={user[0]} />}
          {currentTab === 'report' && <ReportSection user={user[0]} />}
          {currentTab === 'task' && <TaskSection user={user[0]} />}
        </>
      )}
    </Container>
  );
};

export default UserDetails;
