import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Fab, InputBase, Stack, alpha } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import { addSubcollectionItem } from '../../../../services/addServices';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../../../../constants/collections';
import { getSubcollectionItems } from '../../../../services/getServices';
import Tasks from '../PendingTask/Tasks';

const TaskSection = ({ user }) => {
  const [assignedTask, setAssignedTask] = useState();
  const [isAssigning, setIsAssigning] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [pendingTasks, setPendingTasks] = useState([]);

  const handleAssignTask = async () => {
    try {
      setIsAssigning(true);
      const res = await addSubcollectionItem(
        FIREBASE_COLLECTIONS.users,
        user?.id,
        FIREBASE_SUB_COLLECTIONS.pendingTask,
        {
          task: assignedTask,
          isCompleted: false,
        }
      );
      setIsAssigning(false);
      setAssignedTask('');
      enqueueSnackbar('Pending task submited successfully.');
    } catch (error) {
      console.log('error :>> ', error);
      setIsAssigning(false);
      enqueueSnackbar('There is some issues!', { variant: 'error' });
    }
    setIsAssigning(false);
  };

  useEffect(() => {
    const fetchAllPendingTask = async () => {
      try {
        const response = await getSubcollectionItems(
          FIREBASE_COLLECTIONS.users,
          user?.id,
          FIREBASE_SUB_COLLECTIONS.pendingTask
        );
        setPendingTasks(response);
      } catch (error) {
        console.log('error :>> ', error);
      }
    };
    fetchAllPendingTask();
  }, [user, isAssigning]);

  return (
    <>
      <Card sx={{ p: 3, mb: 5 }}>
        <InputBase
          multiline
          fullWidth
          rows={4}
          placeholder="Want to assign a task to this user....."
          value={assignedTask}
          onChange={(e) => setAssignedTask(e.target.value)}
          sx={{
            p: 2,
            mb: 3,
            borderRadius: 1,
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
          }}
        />

        <Stack direction="row-reverse" alignItems="center" justifyContent="space-between">
          <LoadingButton variant="contained" onClick={handleAssignTask} loading={isAssigning}>
            Assign Task
          </LoadingButton>
        </Stack>
      </Card>
      <Box>
        {pendingTasks.length === 0 ? (
          <h1>There is no Pending Task</h1>
        ) : (
          <Tasks list={pendingTasks} userId={user?.id} />
        )}
      </Box>
    </>
  );
};

export default TaskSection;
