import { doc, increment, updateDoc } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';

export const updateStoryItem = (collectionName, data) =>
  new Promise((resolve) => {
    updateDoc(doc(DB, collectionName, data?.id), {
      ...data,
      updateCount: increment(1),
      updatedAt: new Date(),
    })
      .then(() => resolve(data?.id))
      .catch(() => resolve(false));
  });

export const updateSubcollectionItem = (mainCollectionName, docId, subcollectionName, data) =>
  new Promise((resolve) => {
    const subDocRef = doc(DB, mainCollectionName, docId, subcollectionName, data?.id);

    updateDoc(subDocRef, {
      ...data,
      updateCount: increment(1),
      updatedAt: new Date(),
    })
      .then(() => resolve(data?.id))
      .catch(() => resolve(false));
  });
