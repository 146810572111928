import { DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { DialogAnimate } from '../../../components/animate';
import AccountProfile from './AccountProfile';

const UserProfileFormDialog = ({isModelOpen,setIsOpenModel}) => {
  return (
    <>
      <DialogAnimate open={isModelOpen}>
        <DialogTitle>Update Profile</DialogTitle>
        <AccountProfile isModelOpen={isModelOpen} setIsOpenModel={setIsOpenModel} />
      </DialogAnimate>
    </>
  );
};

export default UserProfileFormDialog;
