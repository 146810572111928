export const FIREBASE_COLLECTIONS = {
  users: 'users',
  ratings: 'ratings',
  questionAttributes: 'questionAttributes',
  paperAttributes: 'paperAttributes',
  questions: 'questions',
  schools: 'schools',
  papers: 'papers',
};

export const FIREBASE_SUB_COLLECTIONS = {
  dailyActivity: 'dailyActivity',
  report: 'report',
  pendingTask: 'pendingTask',
};
