import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Card, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Iconify from '../../../../components/Iconify';
import FileDataActivity from '../../../../components/chart/FileDataActivity';
import { getSubcollectionItems } from '../../../../services/getServices';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../../../../constants/collections';

const TIME_LABELS = {
  week: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
};

const ProfileHome = ({ info }) => {
  const theme = useTheme();
  // charts
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);

  // chart
  useEffect(() => {
    const fetchDailyActivity = async () => {
      const res = await getSubcollectionItems(
        FIREBASE_COLLECTIONS.users,
        info?.id,
        FIREBASE_SUB_COLLECTIONS.dailyActivity
      );

      const now = new Date();
      const weekData = new Array(7).fill(0);
      const monthData = new Array(12).fill(0);
      const yearData = [];

      res.forEach((item) => {
        const createdAt = item.createdAt.toDate();
        const totalHours = parseFloat(item.totalHours); // Ensure totalHours is a number

        if (Number.isNaN(totalHours)) {
          // If the current date, ignore this entry as total hours are not yet calculated.
          return;
        }

        const dayOfWeek = createdAt.getDay(); // Sunday - Saturday : 0 - 6
        const month = createdAt.getMonth(); // January - December : 0 - 11

        if (dayOfWeek !== 0) {
          weekData[dayOfWeek - 1] += totalHours;
        } else {
          weekData[6] += totalHours; // Sunday should be the last element in the array
        }

        monthData[month] += totalHours;
      });

      console.log('Week Data:', weekData);
      console.log('Month Data:', monthData);
      console.log('Year Data:', yearData);

      setDailyData(weekData);
      setMonthlyData(monthData);
      setYearlyData(yearData);
    };

    if (info?.id) {
      fetchDailyActivity();
    }
  }, [info]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
        <Card sx={{ py: 3, textAlign: 'center', typography: 'h4' }}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}>
            <Stack width={1}>
              {dailyData?.reduce((total, num) => {
                if (!Number.isNaN(num)) {
                  return total + num;
                }
                return total;
              }, 0)}
              <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
                Weeks Total
              </Box>
            </Stack>

            <Stack width={1}>
              {monthlyData?.reduce((total, num) => {
                if (!Number.isNaN(num)) {
                  return total + num;
                }
                return total;
              }, 0)}
              <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
                Monthly Total
              </Box>
            </Stack>
          </Stack>
        </Card>
        <Stack spacing={3}>
          <Card>
            <CardHeader title="About" />

            <Stack spacing={2} sx={{ p: 3 }}>
              <Box sx={{ typography: 'body2' }}>{info?.about}</Box>

              <Stack direction="row" spacing={2}>
                <Iconify icon="ic:baseline-data-exploration" width={24} />

                <Box sx={{ typography: 'body2' }}>
                  <Typography variant="subtitle2" color="inherit">
                    {`Experience of `} {info?.experience}
                    {` years`}
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" sx={{ typography: 'body2' }}>
                <Iconify icon="fluent:mail-24-filled" width={24} sx={{ mr: 2 }} />
                {info?.email}
              </Stack>

              <Stack direction="row" spacing={2}>
                <Iconify icon="ic:baseline-bloodtype" width={24} />

                <Box sx={{ typography: 'body2' }}>
                  <Typography variant="subtitle2" color="inherit">
                    {`Blood Group `}
                    {info?.bloodGroup}
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Iconify icon="ic:baseline-local-phone" width={24} />

                <Box sx={{ typography: 'body2' }}>
                  <Typography variant="subtitle2" color="inherit">
                    {info?.phoneNumber}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <FileDataActivity
          title="Data Activity"
          chart={{
            labels: TIME_LABELS,
            colors: [theme.palette.primary.main],
            series: [
              {
                type: 'Week',
                data: [{ name: 'Hours', data: dailyData }],
              },
              {
                type: 'Month',
                data: [
                  {
                    name: 'Hours',
                    data: monthlyData,
                  },
                ],
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProfileHome;
