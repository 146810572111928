import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import { USER_ROLES } from '../constants/keywords';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';
import UserDetails from '../pages/dashboard/users/userDetails';
import UserAccount from '../pages/dashboard/users/UserAccount';
import AddUser from '../pages/dashboard/users/AddUser';
import useAuth from '../hooks/useAuth';
import ReportPage from '../pages/dashboard/users/ReportPage';
import RatingPage from '../pages/dashboard/users/RatingPage';
import PendingTask from '../pages/dashboard/users/PendingTask';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  const LANDING_ROUTE = PATH_DASHBOARD.general.app;

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={[USER_ROLES.ADMIN, USER_ROLES.USER]}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={LANDING_ROUTE} replace />, index: true },
        {
          path: 'app',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.ADMIN, USER_ROLES.USER]}>
              <GeneralApp />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'users',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.ADMIN]}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                { element: <LoginList />, index: true },
                { path: ':id/details', element: <UserDetails /> },
              ],
            },
            {
              path: 'account',
              element: <UserAccount />,
            },
            {
              path: 'add',
              element: <AddUser />,
            },
          ],
        },
        {
          path: 'master',
          children: [
            { element: <Navigate to="report" replace />, index: true },
            {
              path: 'report',
              element: <ReportPage />,
            },
            {
              path: 'rating',
              element: <RatingPage />,
            },
            {
              path: 'pendingTask',
              element: <PendingTask />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Outlet />,
      children: [{ element: <Navigate to={LANDING_ROUTE} replace />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// MASTER
const LoginList = Loadable(lazy(() => import('../pages/dashboard/users/index')));

// OTHERS
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
