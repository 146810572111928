import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
// utils
import { fData } from '../../../utils/formatNumber';
// _mock
import { countries } from '../../../_mock';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
  RHFSelectInput,
} from '../../../components/hook-form';
import { bloodGroup } from '../../../_mock/_bloodGroups';
import { updateStoryItem } from '../../../services/updateServices';
import { FIREBASE_COLLECTIONS } from '../../../constants/collections';
import { PATH_DASHBOARD } from '../../../routes/paths';
import uuidv4 from '../../../utils/uuidv4';
import { uploadFile } from '../../../services/storage';
import { USER_STATUS } from '../../../constants/keywords';
import { addStoryItem } from '../../../services/addServices';

// ----------------------------------------------------------------------

export default function AccountProfile({ isModelOpen, setIsOpenModel }) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { user, updateProfile } = useAuth();

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string()
      .required('Name is required')
      .min(2, 'Name is too short - should be 2 chars minimum.')
      .max(50, 'Name is too long - should be 50 chars maximum.'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
    photoURL: Yup.mixed(),
    bloodGroup: Yup.string()
      .required('Blood group is required')
      .oneOf(['a+', 'a-', 'b+', 'b-', 'ab+', 'ab-', 'o+', 'o-'], 'Invalid blood group'),
    experience: Yup.number()
      .required('Experience is required')
      .min(0, 'Experience cannot be negative')
      .max(50, 'Experience is too high - should be 50 years maximum'),
    about: Yup.string()
      .required('About is required')
      .min(10, 'About is too short - should be 10 chars minimum.')
      .max(1000, 'About is too long - should be 1000 chars maximum.'),
  });

  const defaultValues = {
    displayName: user?.displayName || '',
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
    photoURL: user?.photoURL || '',
    bloodGroup: user?.bloodGroup || '',
    experience: user?.experience || '',
    about: user?.about || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      let profilePic;
      console.log('data :>> ', data);
      if (data?.photoURL != null) {
        const token = uuidv4();
        profilePic = await uploadFile(data.photoURL, `ProfilePics/${token}`);
      }

      const payload = {
        ...data,
        designation: 'user',
        status: USER_STATUS.ACTIVE,
        uid: user?.id,
        email: user?.email,
        photoURL: profilePic,
      };

      await addStoryItem(FIREBASE_COLLECTIONS.users, payload, user?.id);

      await updateProfile(user?.id);

      enqueueSnackbar('Updated success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="photoURL"
              accept="image/*"
              maxSize={3145728}
              onDrop={handleDrop}
              setValue={setValue}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="displayName" label="Name" />
              <RHFTextField name="email" disabled label="Email Address" />

              <RHFTextField name="phoneNumber" label="Phone Number" />

              <RHFSelectInput name="bloodGroup" label="Blood Group" placeholder="Blood Group">
                <MenuItem value="" />
                {bloodGroup?.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelectInput>

              <RHFTextField name="experience" label="Experience" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="about" multiline rows={4} label="About" />

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Update Profile
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
