import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { AUTH } from '../../../contexts/FirebaseContext';
// utils
import { fData } from '../../../utils/formatNumber';
// _mock
import { countries } from '../../../_mock';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
  RHFSelectInput,
} from '../../../components/hook-form';
import { bloodGroup } from '../../../_mock/_bloodGroups';
import { addStoryItem } from '../../../services/addServices';
import { FIREBASE_COLLECTIONS } from '../../../constants/collections';
import { USER_STATUS } from '../../../constants/keywords';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function AddUser() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();

  const UpdateUserSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      console.log('data :>> ', data);
      const formData = {
        ...data,
        designation: 'user',
        status: USER_STATUS.ACTIVE,
      };
      const res = await createUserWithEmailAndPassword(AUTH, data?.email, data?.password);
      const response = await addStoryItem(
        FIREBASE_COLLECTIONS.users,
        {
          uid: res.user?.uid,
          createdAt: new Date(),
          createdBy: res.user?.uid,
          ...formData,
          isFirstTime: true,
        },
        res.user?.uid
      );
      await logout();

      navigate(PATH_DASHBOARD.users.list);
      enqueueSnackbar('User Added Successfully!');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="email" label="Email Address" />
              <RHFTextField name="password" label="Password" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Add User
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
