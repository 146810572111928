import { doc, getDoc } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';

export const getSubcollectionDocument = (mainCollectionName, docId, subcollectionName, documentName) =>
  new Promise((resolve, reject) => {
    const docRef = doc(DB, mainCollectionName, docId, subcollectionName, documentName);

    getDoc(docRef)
      .then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          resolve({
            id: documentSnapshot.id,
            ...documentSnapshot.data(),
          });
        } else {
          resolve({});
        }
      })
      .catch((error) => {
        console.error('Error fetching document:', error);
        reject(error);
      });
  });
