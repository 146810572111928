// Firebase Operators
export const FIREBASE_OPERATORS = {
  LESS_THAN: '<',
  LESS_THAN_EQUAL_TO: '<=',
  EQUAL_TO: '==',
  GREATER_THAN: '>',
  GREATER_THAN_EQUAL_TO: '>=',
  NOT_EQUAL_TO: '!=',
  ARRAY_CONTAINS: 'array-contains',
  ARRAY_CONTAINS_ANY: 'array-contains-any',
  IN: 'in',
  NOT_IN: 'not-in',
};
