import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
// utils
import { fData } from '../../../utils/formatNumber';
// _mock
import { countries } from '../../../_mock';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
  RHFSelectInput,
  RHFUploadSingleFile,
  RHFUploadMultiFile,
} from '../../../components/hook-form';
import { bloodGroup } from '../../../_mock/_bloodGroups';
import { updateStoryItem, updateSubcollectionItem } from '../../../services/updateServices';
import { FIREBASE_COLLECTIONS, FIREBASE_SUB_COLLECTIONS } from '../../../constants/collections';
import { PATH_DASHBOARD } from '../../../routes/paths';
import uuidv4 from '../../../utils/uuidv4';
import { uploadFile } from '../../../services/storage';
import Label from '../../../components/Label';
import { formateDDMMYYYY } from '../../../utils/newDateToDDMMYYYY';
import { getSubcollectionDocument } from '../../../services/checkDocExists';
import { addSubcollectionItem } from '../../../services/addServices';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function ReportForm() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { user } = useAuth();

  const UpdateUserSchema = Yup.object().shape({
    // report: Yup.mixed(),
    // report: Yup.array().min(1, 'Report is required'),
    report: Yup.array().min(1, 'Report is required'),
    about: Yup.string()
      .required('Text is required')
      .min(10, 'Text is too short - should be 10 chars minimum.')
      .max(10000, 'Text is too long - should be 1000 chars maximum.'),
  });

  const defaultValues = {
    // report: '',
    report: [],
    about: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data) => {
    try {
      if (data?.report.length > 1) {
        alert('please upload only 1 file');
        return;
      }

      console.log('data :>> ', data);
      const id = user?.id;

      const todayDate = formateDDMMYYYY(new Date());
      const token = uuidv4();
      // const reportDoc = await uploadFile(data?.report, `Reports/${id}/${todayDate}/${token}`);
      const reportDoc = await uploadFile(data?.report[0], `Reports/${id}/${todayDate}/${token}`);

      const documentData = await getSubcollectionDocument(
        FIREBASE_COLLECTIONS.users,
        id,
        FIREBASE_SUB_COLLECTIONS.report,
        todayDate
      );

      if (Object.keys(documentData).length === 0) {
        await addSubcollectionItem(
          FIREBASE_COLLECTIONS.users,
          id,
          FIREBASE_SUB_COLLECTIONS.report,
          {
            reports: [
              {
                doc: reportDoc,
                about: data?.about,
              },
            ],
          },
          todayDate
        );
      } else {
        const reportsData = documentData?.reports || [];
        reportsData.push({
          doc: reportDoc,
          about: data?.about,
        });

        await updateSubcollectionItem(FIREBASE_COLLECTIONS.users, id, FIREBASE_SUB_COLLECTIONS.report, {
          id: documentData?.id,
          reports: reportsData,
        });
      }

      navigate(PATH_DASHBOARD.general.app);
      enqueueSnackbar('Report Submitted Successfully!');
    } catch (error) {
      console.error('er', error);
    }
  };

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     if (file) {
  //       setValue(
  //         'report',
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       );
  //     }
  //   },
  //   [setValue]
  // );

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const files = values.report || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('report', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.report]
  );

  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = values.report && values.report?.filter((file) => file !== inputFile);
      setValue('report', filtered);
    },
    [setValue, values.report]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('report', []);
  }, [setValue]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div>
            <LabelStyle>Report</LabelStyle>
            {/* <RHFUploadSingleFile
              name="report"
              // accept="image/*"
              accept=".doc,.docx,.xls,.xlsx,.pdf,image/*"
              maxSize={3145728}
              onDrop={handleDrop}
            /> */}
            <RHFUploadMultiFile
              multiple
              thumbnail
              // accept=".doc,.docx,.xls,.xlsx,.pdf,image/*"
              accept=".xlsx,.pdf,.jpg,.png,.gif,image,.doc,.docx,.xls,.jpeg"
              name="report"
              // maxSize={3145728}
              maxSize={314572800}
              onDrop={handleDrop}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => console.info('ON UPLOAD')}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="about" multiline rows={4} label="Report" />

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Submit Report
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
